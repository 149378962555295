import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../../components/parts/Layout";
import { snProps } from "../../js/utils";
import { slugFromTag } from "../../js/utils-commonjs.js";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    // site: {
    //   siteMetadata: { title },
    // },
  },
}) => (
  <Layout
    title="Posts by Tag"
    description="Links to lists of posts by tag."
    location={{ pathname: "/blog/tag/" }}
    isBlog={true}
    {...snProps}
  >
    <article className="page">
      <h1 className="title is-3">Posts by Tag</h1>
      <div>
        {group
          .sort((a, b) => a.totalCount < b.totalCount)
          .map((tag) => (
            <p key={tag.fieldValue}>
              <Link to={`/blog/tag/${slugFromTag(tag.fieldValue)}/`}>
                {tag.fieldValue} ({tag.totalCount} Post
                {tag.totalCount === 1 ? "" : "s"})
              </Link>
            </p>
          ))}
      </div>
    </article>
  </Layout>
);

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
